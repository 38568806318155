export default function(text) {
  const lines = text.split(/\r?\n/)

  const entityMap = {}
  let currentKey = 0
  return {
    blocks: lines.map(line => {
      const regex = /{{([^}]*)}}/
      let match = line.match(regex)
      const entityRanges = []
      while (match) {
        line =
          line.substr(0, match.index) +
          match[1] +
          line.substr(match.index + match[0].length)
        entityRanges.push({
          offset: match.index,
          length: match[1].length,
          key: currentKey,
        })
        entityMap[currentKey] = {
          type: 'PLACEHOLDER',
          mutability: 'IMMUTABLE',
        }
        currentKey += 1
        match = line.match(regex)
      }

      return {
        text: line,
        type: 'unstyled',
        entityRanges: entityRanges,
      }
    }),
    entityMap: entityMap,
  }
}
