import './MergeTagList.scoped.scss'

function MergeTag({ mergeTag, insert }) {
  return (
    <li
      onClick={(e) => {
        e.nativeEvent.stopImmediatePropagation();
        if (!mergeTag.disabled) {
          insert(mergeTag)
        }
      }}
      className={`${mergeTag.disabled ? 'disabled' : ''}`}
      title={`${mergeTag.disabled ? 'Contact support to enable' : ''}`}
    >
      <span className="merge-tag">{mergeTag.label}</span>
    </li>
  )
}

export default function MergeTagList({ insert, mergeTags, close, mode = 'label' }) {
  const [filterCategory, setFilterCategory] = useState('All content')
  const [showAll, setShowAll] = useState(false)
  const [search, setSearch] = useState('')
  const mergeTagTests = useSel((s) => _.values(s.mergeTagTests.entities))

  const chooseTag = (mergeTag) => {
    if (mode === 'label') {
      insert(mergeTag.label)
    } else {
      insert(mergeTag.slug)
    }
    close()
  }

  let tagList
  const allTags = Object.values(mergeTags).reduce((acc, val) => acc.concat(val || []), [])

  if (search !== '') {
    const searchTags = allTags.filter((tag) =>
      tag.label.toLowerCase().includes(search.toLowerCase())
    )

    tagList = (
      <div className="merge-tag-list">
        <div className="search-result-list">
          <div className="heading">Search results</div>
          <ul>
            {searchTags.map((mergeTag, index) => (
              <MergeTag key={index} mergeTag={mergeTag} insert={chooseTag} />
            ))}
          </ul>
        </div>
      </div>
    )
  } else {
    const baseTagContainer = { 'All content': allTags }
    if (mergeTagTests.length > 0) {
      baseTagContainer['Experiments'] = mergeTagTests.map((test) => ({
        label: test.name,
        slug: `tests.${test.slug}`,
        disabled: false,
      }))
    }
    mergeTags = Object.assign(baseTagContainer, mergeTags)
    let filteredTags = mergeTags[filterCategory] || [] // Handle when no tags present
    const showMoreLink = filteredTags.length > 15 && !showAll
    if (!showAll) filteredTags = filteredTags.slice(0, 15)

    tagList = (
      <div className="merge-tag-list">
        <div className="category-list">
          <div className="heading">Category</div>
          <ul>
            {Object.keys(mergeTags).map((category, index) => (
              <li
                key={index}
                className={category === filterCategory ? 'active' : null}
                onClick={function () {
                  setShowAll(false)
                  return setFilterCategory(category)
                }}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        <div className="tag-list">
          <div className="heading">Fields</div>
          <ul>
            {filteredTags.map((mergeTag, index) => (
              <MergeTag key={index} mergeTag={mergeTag} insert={chooseTag} />
            ))}
          </ul>
          {showMoreLink && (
            <div
              className="more"
              onClick={function (e) {
                e.nativeEvent.stopImmediatePropagation()
                return setShowAll(true)
              }}
            >
              More
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="popup popup-list search-wrapper">
      <div className="search">
        <input
          type="text"
          placeholder={'Search...'}
          onChange={function (e) {
            setShowAll(false)
            return setSearch(e.target.value || '')
          }}
        />
      </div>
      {tagList}
    </div>
  )
}
