import { convertToHTML } from 'draft-convert'

export default function (contentState) {
  // pass in a hook which reformats a pill entity as a merge tag
  return convertToHTML({
    entityToHTML: (entity, text) => {
      if (entity.type === 'PLACEHOLDER') {
        return `{{${text}}}`
      }
      if (entity.type === 'LINK') {
        return (
          <a style={{ color: entity.data.color }} href={entity.data.url}>
            {text.replace(/&amp;/g, '&')}
          </a>
        )
      }
      return text
    },
  })(contentState)
}
